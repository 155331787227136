import React from 'react';

import NavLinks from '../NavLinks/NavLinks';

function Nav() {
    return (
        <ul className="navigation__pages">
            <NavLinks />
        </ul>
    )
}
export default Nav;